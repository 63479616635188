<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center"></div>
      <v-spacer></v-spacer>
      <span> </span>
    </v-app-bar>

    <v-main>
      <div class="text-center mb-4"></div>
      <HomePage />
    </v-main>
  </v-app>
</template>

<script>
import HomePage from "@/views/HomePage.vue";

export default {
  name: "App",

  components: {
    HomePage,
  },

  data: () => ({

  }),
};
</script>

<style scoped></style>
