<template>
  <div>
    <v-alert
      transition="scale-transition"
      dense
      text
      elevation="0"
      :type="alertType"
      class="mb-0"
      >{{ alertMessage }}</v-alert
    >
    <v-progress-linear
      :value="alertFinishingProgress"
      stream
      color="success"
      class="mb-0 position"
    ></v-progress-linear>
  </div>
</template>
<script>
export default {
  name: "CustomAlert",
  data() {
    return {
      alertFinishingProgress: 0,
      progressIntervalId: null,
    };
  },
  props: {
    alertType: {
      type: String,
      default: "",
    },
    alertMessage: {
      type: String,
      default: "",
    },
  },
  methods: {
    // startProgress() {
    //   let startTime = Date.now();
    //   const duration = 3000; // 3 seconds

    //   this.progressIntervalId = setInterval(() => {
    //     const elapsedTime = Date.now() - startTime;
    //     this.alertFinishingProgress = Math.min(
    //       (elapsedTime / duration) * 100,
    //       100
    //     );

    //     if (this.alertFinishingProgress >= 100) {
    //       clearInterval(this.progressIntervalId);
    //     }
    //   }, 50); // Update every 50 ms
    // },
    startProgress() {
      const duration = 2500; // 3 seconds
      const increment = 100 / (duration / 50); // Increment every 50ms

      this.progressIntervalId = setInterval(() => {
        if (this.alertFinishingProgress >= 100) {
          clearInterval(this.progressIntervalId);
        } else {
          this.alertFinishingProgress += increment;
        }
      }, 50); // Update every 50ms
    },
  },
  mounted() {
    this.startProgress();
  },
};
</script>
<style scoped>
.position {
  margin-top: -5px;
}
</style>
