import { IS_VALID_CREDENTIALS_FORM, CREDENTIALS_FORM_DATA, TOGGLE_CREDENTIALS_FORM } from "../action_types";
import { UPDATE_IS_VALID_CREDENTIALS_FORM, UPDATE_CREDENTIALS_FORM_DATA, UPDATE_TOGGLE_CREDENTIALS_FORM } from "../mutation_types";

const state = {
    isOpenCredentialsForm: false,
    isValidCredentialsForm: false,
    credentialsFromData: {
        questionnaireId: "f945a284-0e6a-417e-bb0a-8147fc47bae4",
        projectKey: "tdRtap_kidqg_nb_ix8E7qWtoSYcsDvxSD3TP3tWV-8=",
        apiKey: "gAAAAABmg5l9JArpBq00a9GeXMk0keaG22RitslKGC9IJcqr32RQA5wzzGPvFE50a8Ff5tqpuA2E0b8LmqvqmMMd3bFIAlEfRqzR6mJoWY9__z_B_Saw2uKjCcsZ477qvl3ezPzBULUB",
        recommendationCount: "5",
    },
};

const actions = {
    [TOGGLE_CREDENTIALS_FORM]({ commit }, { isOpen }) {
        commit(UPDATE_TOGGLE_CREDENTIALS_FORM, isOpen);
    },
    [IS_VALID_CREDENTIALS_FORM]({ commit }, { isValid }) {
        commit(UPDATE_IS_VALID_CREDENTIALS_FORM, isValid);
    },
    [CREDENTIALS_FORM_DATA]({ commit }, { data }) {
        if (data) {
            console.log("Passed credentials action data:", data);
            commit(UPDATE_CREDENTIALS_FORM_DATA, data);
        } else {
            console.log('CREDENTIALS_FORM_DATA is Undefined');
        }
    },
};

const mutations = {
    [UPDATE_TOGGLE_CREDENTIALS_FORM](state, isOpen) {
        state.isOpenCredentialsForm = isOpen;
    },
    [UPDATE_IS_VALID_CREDENTIALS_FORM](state, isValid) {
        state.isValidCredentialsForm = isValid;
    },
    [UPDATE_CREDENTIALS_FORM_DATA](state, data) {
        if (data) {
            console.log("Passed state credentials mutation data:", data);
            state.credentialsFromData = { ...state.credentialsFromData, ...data };
            console.log("Updated", state.credentialsFromData);
        } else {
            console.log('UPDATE_CREDENTIALS_FORM_DATA is Undefined');
        }
    },
};

const getters = {
    isOpenCredentialsForm: (state) => state.isOpenCredentialsForm,
    isValidCredentialsForm: (state) => state.isValidCredentialsForm,
    credentialsFromData: (state) => state.credentialsFromData,
};

export const credentials_form = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
