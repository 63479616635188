var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SnackbarNotifier'),_c('div',[[_c('CredentialsForm'),_c('v-container',[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","color":"blue","small":_vm.$vuetify?.breakpoint?.smAndDown},on:{"click":_vm.getStartedWithQuestionnaire}},[_vm._v(" Get Started ")])],1)],1)]],2),_c('CircularLoader',{attrs:{"isLoading":_vm.isLoading}}),_c('v-dialog',{attrs:{"persistent":true,"width":"900"},model:{value:(_vm.isOpenQuestionnaireDialog),callback:function ($$v) {_vm.isOpenQuestionnaireDialog=$$v},expression:"isOpenQuestionnaireDialog"}},[_c('v-card',{staticClass:"questionnaire-container"},[_c('v-card-title',{staticClass:"d-flex justify-between grey lighten-2 mb-2 questionnaire-title"},[_vm._v(" "+_vm._s(_vm.questionnaireName)+" "),_c('v-spacer'),_c('v-icon',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.isOpenQuestionnaireDialog = false}}},[_vm._v("mdi-close")])],1),_c('v-card-text',{class:[
          'hvr-shutter-out-horizontal',
          { active: _vm.isShowTasteProfileSectionMessage },
        ]},[_c('v-progress-linear',{staticClass:"mb-0 mt-0",attrs:{"value":_vm.displayProgress,"height":"15","rounded":""}},[_c('strong',[_vm._v(_vm._s(`${Math.round(_vm.displayProgress)}%`))])]),_c('v-form',{ref:"stepperForm",model:{value:(_vm.isStepperFormValid),callback:function ($$v) {_vm.isStepperFormValid=$$v},expression:"isStepperFormValid"}},[_c('v-stepper',{attrs:{"elevation":0,"min-height":480},model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('v-stepper-items',_vm._l((_vm.steps),function(step,index){return _c('v-stepper-content',{key:index,attrs:{"step":index}},[(_vm.currentStep === index)?_c('div',[_c('p',{staticClass:"question-text"},[_vm._v(" "+_vm._s(`${index + 1}. ${step.question}`)+" ")]),(step.type === 'text')?_c('v-text-field',{staticClass:"option-text",attrs:{"label":step.question},model:{value:(
                      _vm.questionnaireFormData[step.section_name][step.key]
                    ),callback:function ($$v) {_vm.$set(_vm.questionnaireFormData[step.section_name], step.key, $$v)},expression:"\n                      questionnaireFormData[step.section_name][step.key]\n                    "}}):(step.type === 'radio')?_c('v-radio-group',{staticClass:"option-text",attrs:{"dense":"","rules":[
                      (v) => !!v || 'At least one option must be selected!',
                    ]},model:{value:(
                      _vm.questionnaireFormData[step.section_name][step.key]
                    ),callback:function ($$v) {_vm.$set(_vm.questionnaireFormData[step.section_name], step.key, $$v)},expression:"\n                      questionnaireFormData[step.section_name][step.key]\n                    "}},_vm._l((step.options),function(option,key){return _c('v-radio',{key:key,attrs:{"label":option,"value":key}})}),1):(step.type === 'checkbox')?_c('v-container',{attrs:{"fluid":""}},_vm._l((step.options),function(option,key,index){return _c('v-checkbox',{key:key,staticClass:"option-text",attrs:{"multiple":"","dense":"","hide-details":index !== Object.keys(step.options).length - 1,"label":option,"value":key,"rules":[
                        (v) =>
                          (v && v.length > 0) ||
                          'At least one option must be selected!',
                      ]},model:{value:(
                        _vm.questionnaireFormData[step.section_name][step.key]
                      ),callback:function ($$v) {_vm.$set(_vm.questionnaireFormData[step.section_name], step.key, $$v)},expression:"\n                        questionnaireFormData[step.section_name][step.key]\n                      "}})}),1):(step.type === 'dropdown')?_c('v-container',{attrs:{"fluid":""}},[_c('v-select',{staticClass:"option-text",attrs:{"label":"Select an option","dense":"","items":Object.entries(step.options).map(([key, value]) => ({
                          text: value,
                          value: key,
                        })),"item-text":"text","item-value":"value","rules":[
                        (v) => !!v || 'At least one option must be selected!',
                      ]},model:{value:(
                        _vm.questionnaireFormData[step.section_name][step.key]
                      ),callback:function ($$v) {_vm.$set(_vm.questionnaireFormData[step.section_name], step.key, $$v)},expression:"\n                        questionnaireFormData[step.section_name][step.key]\n                      "}})],1):_vm._e()],1):_vm._e()])}),1)],1)],1)],1),(_vm.isShowTasteProfileSectionMessage)?_c('div',{staticClass:"taste-profile-start-content"},[_c('p',[_vm._v("From now taste profile questions starts")])]):_vm._e(),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"blue","dark":"","disabled":_vm.currentStep === 0 || _vm.currentStep === _vm.steps.length,"small":_vm.$vuetify?.breakpoint?.smAndDown},on:{"click":_vm.prevStep}},[_vm._v("Back")]),(_vm.currentStep < _vm.steps.length - 1)?_c('v-btn',{class:[{ 'd-none': _vm.isShowTasteProfileSectionMessage }],attrs:{"color":"blue","dark":"","small":_vm.$vuetify?.breakpoint?.smAndDown},on:{"click":_vm.nextStep}},[_vm._v("Next")]):_vm._e(),(_vm.currentStep === _vm.steps.length - 1)?_c('v-btn',{attrs:{"color":"Black lighten-1","dark":"","small":_vm.$vuetify?.breakpoint?.smAndDown},on:{"click":_vm.submit}},[_vm._v("Submit")]):_vm._e(),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowTasteProfileSectionMessage),expression:"isShowTasteProfileSectionMessage"}],attrs:{"color":"blue","dark":"","small":_vm.$vuetify?.breakpoint?.smAndDown},on:{"click":_vm.removeShutterEffect}},[_vm._v("Next")])],1)],1)],1),(_vm.isGotRecommendedProducts)?_c('div',[_c('v-container',[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"cols":"12","sm":"10","md":"8","lg":"8"}},[_c('div',{class:[
              _vm.$vuetify.breakpoint.xs ? 'text-body-2' : 'text-subtitle-1',
            ]},[_c('p',{staticClass:"mb-4 font-weight-bold"},[_vm._v("あなたのタイプ:")]),_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.userTestProfileTypes),function(values,type){return _c('v-chip-group',{key:type,staticClass:"me-1 me-sm-2",attrs:{"column":"","multiple":""}},_vm._l((values),function(value){return _c('v-chip',{key:value,class:[_vm.$vuetify.breakpoint.xs ? 'small' : ''],attrs:{"outlined":"","small":""}},[_vm._v(" "+_vm._s(value)+" ")])}),1)}),1),_c('div',[_c('p',{staticClass:"mt-3 mt-md-4"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" 味覚カテゴリー: ")]),_vm._v(" "+_vm._s(_vm.tasteProfileCategoryAndExplanation.category)+" ")])]),_c('div',[_c('p',{staticClass:"mt-3 mt-md-4"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.tasteProfileCategoryAndExplanation.category)+" カテゴリの意味: ")])]),_c('ul',_vm._l((_vm.tasteProfileCategoryAndExplanation.meaning),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item)+" ")])}),0)]),_c('div',[_c('p',{staticClass:"mt-3 mt-md-4"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("説明: ")]),_vm._v(" "+_vm._s(_vm.tasteProfileCategoryAndExplanation.explanation)+" ")])])])])],1),_vm._l((_vm.recommendedProducts),function(allProducts,category){return _c('div',{key:category},[_c('div',{staticClass:"text-h6 my-4 text-center"},[_vm._v(" "+_vm._s(_vm.recommendedProducts[category].length > 0 ? category : "")+" ")]),_c('v-row',{staticClass:"d-flex justify-center"},_vm._l((allProducts),function(product){return _c('v-col',{key:product?.item_id,attrs:{"cols":"12","sm":"10","md":"8","lg":"8"}},[_c('ProductCard',{attrs:{"id":product?.item_id,"name":product?.title,"image":product?.image_url,"price":product?.price,"description":product?.description,"tasteType":product?.taste_type,"ingredientType":product?.ingredient_type,"recommendedWayToDrink":product?.recommendedWayToConsume}})],1)}),1)],1)})],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }