<template>
  <div>
    <SnackbarNotifier />
    <div>
      <template>
        <CredentialsForm />
        <v-container>
          <v-row class="d-flex justify-center"
            ><v-btn
              @click="getStartedWithQuestionnaire"
              class="ma-2"
              outlined
              color="blue"
              :small="$vuetify?.breakpoint?.smAndDown"
            >
              Get Started
            </v-btn></v-row
          >
        </v-container>
      </template>
    </div>
    <CircularLoader :isLoading="isLoading" />
    <v-dialog
      v-model="isOpenQuestionnaireDialog"
      :persistent="true"
      width="900"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="blue lighten-2" dark v-bind="attrs" v-on="on" :disabled="isLoading">
          Get Started
        </v-btn>
      </template> -->

      <v-card class="questionnaire-container">
        <v-card-title
          class="d-flex justify-between grey lighten-2 mb-2 questionnaire-title"
        >
          {{ questionnaireName }}
          <v-spacer></v-spacer>
          <v-icon
            @click="isOpenQuestionnaireDialog = false"
            class="cursor-pointer"
            >mdi-close</v-icon
          >
        </v-card-title>

        <v-card-text
          :class="[
            'hvr-shutter-out-horizontal',
            { active: isShowTasteProfileSectionMessage },
          ]"
        >
          <v-progress-linear
            :value="displayProgress"
            class="mb-0 mt-0"
            height="15"
            rounded
          >
            <strong>{{ `${Math.round(displayProgress)}%` }}</strong>
          </v-progress-linear>

          <v-form ref="stepperForm" v-model="isStepperFormValid">
            <v-stepper :elevation="0" :min-height="480" v-model="currentStep">
              <v-stepper-items>
                <v-stepper-content
                  v-for="(step, index) in steps"
                  :key="index"
                  :step="index"
                >
                  <div v-if="currentStep === index">
                    <p class="question-text">
                      {{ `${index + 1}. ${step.question}` }}
                    </p>
                    <v-text-field
                      v-if="step.type === 'text'"
                      :label="step.question"
                      v-model="
                        questionnaireFormData[step.section_name][step.key]
                      "
                      class="option-text"
                    ></v-text-field>
                    <v-radio-group
                      class="option-text"
                      v-else-if="step.type === 'radio'"
                      dense
                      v-model="
                        questionnaireFormData[step.section_name][step.key]
                      "
                      :rules="[
                        (v) => !!v || 'At least one option must be selected!',
                      ]"
                    >
                      <v-radio
                        v-for="(option, key) in step.options"
                        :key="key"
                        :label="option"
                        :value="key"
                      ></v-radio>
                    </v-radio-group>

                    <v-container v-else-if="step.type === 'checkbox'" fluid>
                      <v-checkbox
                        class="option-text"
                        v-for="(option, key, index) in step.options"
                        :key="key"
                        multiple
                        dense
                        :hide-details="
                          index !== Object.keys(step.options).length - 1
                        "
                        :label="option"
                        :value="key"
                        v-model="
                          questionnaireFormData[step.section_name][step.key]
                        "
                        :rules="[
                          (v) =>
                            (v && v.length > 0) ||
                            'At least one option must be selected!',
                        ]"
                      >
                      </v-checkbox>
                    </v-container>

                    <!-- Dropdown Section -->
                    <v-container v-else-if="step.type === 'dropdown'" fluid>
                      <v-select
                        class="option-text"
                        label="Select an option"
                        dense
                        v-model="
                          questionnaireFormData[step.section_name][step.key]
                        "
                        :items="
                          Object.entries(step.options).map(([key, value]) => ({
                            text: value,
                            value: key,
                          }))
                        "
                        item-text="text"
                        item-value="value"
                        :rules="[
                          (v) => !!v || 'At least one option must be selected!',
                        ]"
                      ></v-select>
                    </v-container>
                  </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-form>
        </v-card-text>
        <div
          v-if="isShowTasteProfileSectionMessage"
          class="taste-profile-start-content"
        >
          <p>From now taste profile questions starts</p>
          <!-- <v-btn class="continue-btn" @click="removeShutterEffect">
            Continue
          </v-btn> -->
        </div>

        <v-divider></v-divider>

        <v-card-actions class="justify-end">
          <v-btn
            @click="prevStep"
            color="blue"
            dark
            :disabled="currentStep === 0 || currentStep === steps.length"
            :small="$vuetify?.breakpoint?.smAndDown"
            >Back</v-btn
          >
          <v-btn
            :class="[{ 'd-none': isShowTasteProfileSectionMessage }]"
            @click="nextStep"
            color="blue"
            dark
            v-if="currentStep < steps.length - 1"
            :small="$vuetify?.breakpoint?.smAndDown"
            >Next</v-btn
          >
          <v-btn
            @click="submit"
            color="Black lighten-1"
            dark
            v-if="currentStep === steps.length - 1"
            :small="$vuetify?.breakpoint?.smAndDown"
            >Submit</v-btn
          >
          <v-btn
            v-show="isShowTasteProfileSectionMessage"
            @click="removeShutterEffect"
            color="blue"
            dark
            :small="$vuetify?.breakpoint?.smAndDown"
            >Next</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="isGotRecommendedProducts">
      <v-container>
        <v-row class="d-flex justify-center">
          <v-col cols="12" sm="10" md="8" lg="8">
            <div
              :class="[
                $vuetify.breakpoint.xs ? 'text-body-2' : 'text-subtitle-1',
              ]"
            >
              <p class="mb-4 font-weight-bold">あなたのタイプ:</p>
              <div class="d-flex flex-wrap">
                <v-chip-group
                  v-for="(values, type) in userTestProfileTypes"
                  :key="type"
                  class="me-1 me-sm-2"
                  column
                  multiple
                >
                  <v-chip
                    v-for="value in values"
                    :key="value"
                    outlined
                    small
                    :class="[$vuetify.breakpoint.xs ? 'small' : '']"
                  >
                    {{ value }}
                  </v-chip>
                </v-chip-group>
              </div>
              <div>
                <p class="mt-3 mt-md-4">
                  <span class="font-weight-bold"> 味覚カテゴリー: </span>
                  {{ tasteProfileCategoryAndExplanation.category }}
                </p>
              </div>
              <div>
                <p class="mt-3 mt-md-4">
                  <span class="font-weight-bold">
                    {{ tasteProfileCategoryAndExplanation.category }}
                    カテゴリの意味:
                  </span>
                </p>
                <ul>
                  <li
                    v-for="(
                      item, index
                    ) in tasteProfileCategoryAndExplanation.meaning"
                    :key="index"
                  >
                    {{ item }}
                  </li>
                </ul>
              </div>
              <div>
                <p class="mt-3 mt-md-4">
                  <span class="font-weight-bold">説明: </span>
                  {{ tasteProfileCategoryAndExplanation.explanation }}
                </p>
              </div>
            </div>
          </v-col>
        </v-row>

        <div
          v-for="(allProducts, category) in recommendedProducts"
          :key="category"
        >
          <div class="text-h6 my-4 text-center">
            {{ recommendedProducts[category].length > 0 ? category : "" }}
          </div>
          <v-row class="d-flex justify-center">
            <v-col
              v-for="product in allProducts"
              :key="product?.item_id"
              cols="12"
              sm="10"
              md="8"
              lg="8"
            >
              <ProductCard
                :id="product?.item_id"
                :name="product?.title"
                :image="product?.image_url"
                :price="product?.price"
                :description="product?.description"
                :tasteType="product?.taste_type"
                :ingredientType="product?.ingredient_type"
                :recommendedWayToDrink="product?.recommendedWayToConsume"
              />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import axios from "axios";
import ProductCard from "@/components/ProductCard.vue";
import CredentialsForm from "@/components/CredentialsForm.vue";
import CircularLoader from "@/components/CircularLoader.vue";
import SnackbarNotifier from "@/components/snackbar/SnackbarNotifier.vue";
import { SNACKBAR_NOTIFIER } from "@/store/action_types";

export default {
  name: "QuestionnairePage",
  components: {
    CircularLoader,
    ProductCard,
    CredentialsForm,
    SnackbarNotifier,
  },
  data() {
    return {
      isCredentialsFormValid: "",
      requiredKeys: {
        item_id: "",
        title: "",
        image_url: "",
        price: "",
        description: "",
        taste_type: "",
        ingredient_type: "",
        recommendedWayToConsume: "",
      },
      isLoading: false,
      isOpenQuestionnaireDialog: false,
      currentStep: 0,
      questionnaireFormData: {},
      steps: [],
      displayProgress: 0,
      isStepValid: false,
      isStepperFormValid: true,
      questionnaireName: "",
      isGotRecommendedProducts: false,
      recommendedProducts: {},
      userTestProfileTypes: {},
      tasteProfileCategoryAndExplanation: {},
      mergedQuestions: {},
      kikusuiGeneralQuestions: {},
      tasteProfileQuestions: {
        q1: {
          question: "性別?",
          type: "radio",
          options: {
            a: "男性",
            b: "女性",
            c: "その他",
            d: "無回答",
          },
        },
        q2: {
          question: "年齢?",
          type: "radio",
          options: {
            a: "〜19",
            b: "20～24",
            c: "25～29",
            d: "30～34",
            e: "35～39",
            f: "40～44",
            g: "45～49",
            h: "50～54",
            i: "55～59",
            j: "60～64",
            k: "65～69",
            l: "70～",
          },
        },
        q3: {
          question: "居住地？",
          type: "radio",
          options: {
            a: "北海道",
            b: "青森県",
            c: "岩手県",
            d: "宮城県",
            e: "秋田県",
            f: "山形県",
            g: "福島県",
            h: "茨城県",
            i: "栃木県",
            j: "群馬県",
            k: "埼玉県",
            l: "千葉県",
            m: "東京都",
            n: "神奈川県",
            o: "新潟県",
            p: "富山県",
            q: "石川県",
            r: "福井県",
            s: "山梨県",
            t: "長野県",
            u: "岐阜県",
            v: "静岡県",
            w: "愛知県",
            x: "三重県",
            y: "滋賀県",
            z: "京都府",
            aa: "大阪府",
            ab: "兵庫県",
            ac: "奈良県",
            ad: "和歌山県",
            ae: "鳥取県",
            af: "島根県",
            ag: "岡山県",
            ah: "広島県",
            ai: "山口県",
            aj: "徳島県",
            ak: "香川県",
            al: "愛媛県",
            am: "高知県",
            an: "福岡県",
            ao: "佐賀県",
            ap: "長崎県",
            aq: "熊本県",
            ar: "大分県",
            as: "宮崎県",
            at: "鹿児島県",
            au: "沖縄県",
          },
        },
        q4: {
          question: "出身地？",
          type: "radio",
          options: {
            a: "北海道",
            b: "青森県",
            c: "岩手県",
            d: "宮城県",
            e: "秋田県",
            f: "山形県",
            g: "福島県",
            h: "茨城県",
            i: "栃木県",
            j: "群馬県",
            k: "埼玉県",
            l: "千葉県",
            m: "東京都",
            n: "神奈川県",
            o: "新潟県",
            p: "富山県",
            q: "石川県",
            r: "福井県",
            s: "山梨県",
            t: "長野県",
            u: "岐阜県",
            v: "静岡県",
            w: "愛知県",
            x: "三重県",
            y: "滋賀県",
            z: "京都府",
            aa: "大阪府",
            ab: "兵庫県",
            ac: "奈良県",
            ad: "和歌山県",
            ae: "鳥取県",
            af: "島根県",
            ag: "岡山県",
            ah: "広島県",
            ai: "山口県",
            aj: "徳島県",
            ak: "香川県",
            al: "愛媛県",
            am: "高知県",
            an: "福岡県",
            ao: "佐賀県",
            ap: "長崎県",
            aq: "熊本県",
            ar: "大分県",
            as: "宮崎県",
            at: "鹿児島県",
            au: "沖縄県",
          },
        },
        q5: {
          question: "職業？",
          type: "radio",
          options: {
            a: "無職",
            b: "自営業",
            c: "会社員",
            d: "農業",
            e: "林業",
            f: "漁業",
            g: "飲食業",
          },
        },
        q6: {
          question: "普段のコーヒーの飲み方はどちらですか？",
          type: "radio",
          options: {
            a: "それ以外（コーヒーを飲まない場合もこちら）",
            b: "ブラック",
          },
        },
        q7: {
          question: "好きなフルーツジュースはどちらですか？",
          type: "radio",
          options: {
            a: "パイナップルジュース",
            b: "グレープフルーツジュース",
          },
        },
        q8: {
          question: "もちの食べ方で好きなのはどちらですか？",
          type: "radio",
          options: {
            a: "お雑煮",
            b: "磯辺焼",
          },
        },
        q9: {
          question: "魚の食べ方で好きなのはどちらですか？",
          type: "radio",
          options: {
            a: "焼魚",
            b: "煮魚・照焼",
          },
        },
        q10: {
          question: "焼き鳥（もも）はどちらの食べ方が好きですか？",
          type: "radio",
          options: {
            a: "塩",
            b: "タレ",
          },
        },
        q11: {
          question: "冷やし中華で好きなのはどちらですか？",
          type: "radio",
          options: {
            a: "ゴマだれ",
            b: "醤油だれ",
          },
        },
        q12: {
          question: "好きな缶詰のフルーツはどちらですか？",
          type: "radio",
          options: {
            a: "もも缶",
            b: "ミカン缶",
          },
        },
        q13: {
          question: "厚焼き玉子で好きなのはどちらですか？",
          type: "radio",
          options: {
            a: "出汁巻き",
            b: "砂糖入り",
          },
        },
        q14: {
          question: "かき氷のシロップで好きなのはどちらですか？",
          type: "radio",
          options: {
            a: "メロン",
            b: "レモン",
          },
        },
        q15: {
          question: "昼の定食に小鉢をとるならどちらですか？",
          type: "radio",
          options: {
            a: "春菊のお浸し",
            b: "かぼちゃの煮物",
          },
        },
        q16: {
          question: "チョコレートで好きなのはどれですか？",
          type: "radio",
          options: {
            a: "ハイカカオチョコレート",
            b: "ホワイトチョコレート",
          },
        },
        q17: {
          question: "お弁当に入っていると嬉しいのはどれですか？",
          type: "radio",
          options: {
            a: "海苔の佃煮",
            b: "ふきの煮物",
            c: "フライドポテト",
            d: "大学いも",
            e: "きゅうりの酢の物",
          },
        },
        q18: {
          question: "ケーキで好きなのはどれですか？",
          type: "radio",
          options: {
            a: "モンブラン",
            b: "フルーツタルト",
            c: "チョコレートケーキ",
            d: "チーズケーキ",
            e: "ショートケーキ",
          },
        },
        q19: {
          question: "おせち料理で好きなのはどれですか？",
          type: "radio",
          options: {
            a: "田作り",
            b: "数の子",
            c: "栗きんとん",
            d: "なます",
            e: "伊達巻",
          },
        },
        q20: {
          question: "サラダのドレッシングで好きなのはどれですか？",
          type: "radio",
          options: {
            a: "青じそドレッシング",
            b: "中華ドレッシング",
            c: "玉ねぎドレッシング",
            d: "シーザードレッシング",
            e: "和風醤油ドレッシング",
          },
        },
      },
      categoryMeaning: {
        A_1: {
          class: "A_1",
          meaning: "パターンの特徴\n甘味を好む\n苦味に対する受容が低い",
        },
        A_2: {
          class: "A_2",
          meaning: "パターンの特徴\n塩気を控えた薄味を好む",
        },
        A_3: {
          class: "A_3",
          meaning: "パターンの特徴\n甘味とうま味を好む\n苦味の受容が低い",
        },
        A_4: {
          class: "A_4",
          meaning:
            "パターンの特徴\n甘味とうま味を好む\n塩気の控えた薄味を好む\n苦味の受容が低い",
        },
        U_1: {
          class: "U_1",
          meaning:
            "パターンの特徴\n塩気が控えめな薄味で、うま味の強い味わいを好む",
        },
        U_2: {
          class: "U_2",
          meaning:
            "パターンの特徴\n塩気が控えめな薄味で、うま味の強い味わいを好む\n苦味の受容が低い",
        },
        U_3: {
          class: "U_3",
          meaning: "パターンの特徴\n薄味で酸味のある味を好む\n苦味の受容が低い",
        },
        U_4: {
          class: "U_4",
          meaning: "パターンの特徴\nうま味、酸味の強い味を好む",
        },
        C_1: {
          class: "C_1",
          meaning:
            "パターンの特徴\nバランスのいいタイプ\n苦味に対する受容が低い",
        },
        C_2: {
          class: "C_2",
          meaning: "パターンの特徴\n甘味を好む\n苦味に対する受容が低い",
        },
        C_3: {
          class: "C_3",
          meaning:
            "パターンの特徴\n甘味・塩味のしっかりとした味を好む\n苦味に対する受容が低い",
        },
        B_1: {
          class: "B_1",
          meaning: "パターンの特徴\nバランスのいいタイプ",
        },
        B_2: {
          class: "B_2",
          meaning:
            "パターンの特徴\nバランスのいいタイプ\nややはっきりとした味付けを好む",
        },
        B_3: {
          class: "B_3",
          meaning:
            "パターンの特徴\n塩気の強い味わいより、苦味・酸味の刺激を好む",
        },
        S_1: {
          class: "S_1",
          meaning: "パターンの特徴\n塩気の強い濃い味を好む\n酸味の刺激を好む",
        },
        S_2: {
          class: "S_2",
          meaning:
            "パターンの特徴\n塩気の強い濃い味を好む\n酸味の刺激は好むが、苦味に対する受容は低い",
        },
        S_3: {
          class: "S_3",
          meaning:
            "パターンの特徴\n塩気の強い濃い味を好む\n苦味に対する受容が低い",
        },
        N_1: {
          class: "N_1",
          meaning: "パターンの特徴\n甘さよりも苦味の強い味を好む",
        },
        N_2: {
          class: "N_2",
          meaning: "パターンの特徴\n塩気の強い濃い味を好む\n苦味の許容が高い",
        },
        N_3: {
          class: "N_3",
          meaning: "パターンの特徴\n苦味を好む",
        },
        N_4: {
          class: "N_4",
          meaning:
            "パターンの特徴\n塩味、酸味、苦味と言ったしっかりとした濃い味を好む\n甘味は好まない",
        },
        N_5: {
          class: "N_5",
          meaning: "パターンの特徴\n塩気の強い濃い味を好む",
        },
      },
      isShowTasteProfileSectionMessage: false,
    };
  },
  computed: {
    ...mapGetters("credentials_form", [
      "isOpenCredentialsForm",
      "credentialsFromData",
      "isValidCredentialsForm",
    ]),

    progressCalculate() {
      if (this.steps.length === 0) {
        return 0;
      }
      let calculate = (this.currentStep / this.steps.length) * 100;
      return calculate;
    },
    extractTasteProfile() {
      const { tasteTypes, ingredientTypes } = this.processTasteProfile(
        this.recommendedProducts
      );
      // Combine tasteTypes and ingredientTypes into one array
      const combinedTypes = [...tasteTypes, ...ingredientTypes];

      // Create a unique array by using a Set
      const uniqueTypes = [...new Set(combinedTypes)];

      return uniqueTypes;
    },
  },
  methods: {
    ...mapActions("snackbar_notifier", [SNACKBAR_NOTIFIER]),

    async nextStep() {
      const isValid = await this.$refs.stepperForm.validate();
      // if (
      //   this.isShowTasteProfileSectionMessage === true &&
      //   this.currentStep === 20
      // ) {
      //   this.showSnackbarNotifier(
      //     "Please press the continue button to proceed!",
      //     "warning",
      //     2000
      //   );
      //   return;
      // }
      if (!isValid) {
        return;
      }

      if (this.currentStep < this.steps.length - 1) {
        this.currentStep++;
      }

      console.log("this.questionnaireFormData:\n", this.questionnaireFormData);
    },
    async prevStep() {
      if (this.currentStep > 0) {
        if (this.currentStep == 20) {
          this.isShowTasteProfileSectionMessage = false;
        }
        this.currentStep--;
      }
    },
    async removeShutterEffect() {
      this.isShowTasteProfileSectionMessage = false;
    },
    processTasteProfile(data) {
      const tasteTypes = new Set();
      const ingredientTypes = new Set();

      Object.values(data).forEach((products) =>
        products.forEach((product) => {
          (product.taste_type || []).forEach((type) => tasteTypes.add(type));
          (product.ingredient_type || []).forEach((type) =>
            ingredientTypes.add(type)
          );
        })
      );

      return {
        tasteTypes: [...tasteTypes],
        ingredientTypes: [...ingredientTypes],
      };
    },

    async extractTasteClassMeaningAndExplanation(gptResponse) {
      try {
        const responseStr = gptResponse.detail.response
          .replace(/```json\n/, "")
          .replace(/\n```$/, "");

        const responseObj = await JSON.parse(responseStr);
        const meaningText = this.categoryMeaning[responseObj.result].meaning;
        const meaningArray = meaningText.split("\n");

        this.tasteProfileCategoryAndExplanation = {
          category: responseObj.result,
          meaning: meaningArray,
          explanation: responseObj.explanation,
        };
        console.log(
          "tasteProfileCategoryAndExplanation",
          this.tasteProfileCategoryAndExplanation
        );
        console.log(
          "\ncategory: ",
          responseObj.result,
          "\nmeaning: ",
          meaningArray,
          "\nexplanation: ",
          responseObj.explanation
        );
      } catch (error) {
        console.error("Error parsing response:", error);
        return null;
      }
    },

    separateAndReorderProfilesData(data) {
      console.log("separateAndReorderProfilesData: ", data);
      // Extract section names dynamically
      const sectionNames = Object.keys(data);
      const kikusuiGeneralQuestionsData = data[sectionNames[0]];
      const tasteProfileQuestionsData = data[sectionNames[1]];
      console.log(kikusuiGeneralQuestionsData);
      console.log(tasteProfileQuestionsData);

      // Reorder map for kikusuiProfile
      const kikusuiProfileMap = {
        q1: "q1",
        q2: "q2",
        q3: "q3",
        q4: "q4",
        q5: "q5",
        q6: "q6",
        q7: "q7",
        q8: "q8",
        q9: "q9",
        q10: "q10",
        q11: "q11",
        q12: "q12",
        q13: "q13",
        q14: "q14",
        q15: "q15",
        q16: "q16",
        q17: "q17",
        q18: "q18",
        q19: "q19",
        q20: "q20",
      };

      // Reorder map for tasteProfile
      const tasteProfileMap = {
        q1: "q1",
        q2: "q2",
        q3: "q1",
        q4: "q2",
        q5: "q3",
        q6: "q4",
        q7: "q5",
        q8: "q6",
        q9: "q7",
        q10: "q8",
        q11: "q9",
        q12: "q10",
        q13: "q11",
        q14: "q12",
        q15: "q13",
        q16: "q14",
        q17: "q15",
        q18: "q16",
        q19: "q17",
        q20: "q18",
      };

      // Create new kikusuiProfile object based on the reordering map
      let kikusuiProfileAnswers = {};
      for (const [oldKey, newKey] of Object.entries(kikusuiProfileMap)) {
        if (kikusuiGeneralQuestionsData[oldKey] !== undefined) {
          kikusuiProfileAnswers[newKey] = kikusuiGeneralQuestionsData[oldKey];
        }
      }

      // Create new tasteProfile object based on the reordering map
      let tasteProfileAnswers = {};
      for (const [oldKey, newKey] of Object.entries(tasteProfileMap)) {
        // Check if the key belongs to Kikusui for q1 and q2, else use Taste data
        if (
          kikusuiGeneralQuestionsData[oldKey] !== undefined &&
          oldKey === newKey
        ) {
          tasteProfileAnswers[oldKey] = kikusuiGeneralQuestionsData[newKey];
        } else {
          console.log(
            "\n............................................................\n"
          );
          console.log(oldKey);
          console.log(tasteProfileQuestionsData[newKey]);
          console.log(
            "\n............................................................\n"
          );
          tasteProfileAnswers[oldKey] = tasteProfileQuestionsData[newKey];
        }
      }
      // for (const [oldKey, newKey] of Object.entries(tasteProfileMap)) {
      //   if (tasteProfileQuestionsData[oldKey] !== undefined) {
      //     tasteProfileAnswers[newKey] = answers[oldKey];
      //   }
      // }
      console.log("kikusuiProfileAnswers: ", kikusuiProfileAnswers);
      console.log("tasteProfileAnswers: ", tasteProfileAnswers);
      // Return the structure with the dynamic key, maintaining the correct format
      return {
        kikusuiProfile: {
          [sectionNames[0]]: kikusuiProfileAnswers,
        },
        tasteProfile: tasteProfileAnswers,
      };
    },

    generateQuery(questions, answers) {
      // Helper function to get the answer text from the option key
      const getAnswerText = (questionKey, answerKey) => {
        const question = questions[questionKey];
        return question.options[answerKey];
      };

      // Build the first section: 診断者情報
      let query = "## 診断者情報\n\n";

      // Process questions 1-5 (Personal info)
      for (let i = 1; i <= 5; i++) {
        const questionKey = `q${i}`;
        const answerKey = answers[questionKey];
        const questionText = questions[questionKey].question;
        const answerText = getAnswerText(questionKey, answerKey);

        query += `Q${i}: ${questionText}\n`;
        query += `A${i}: ${answerText}\n\n`;
      }

      // Build the second section: 嗜好性について
      query += "## 嗜好性について\n\n";

      // Process questions 6-20 (Taste preferences)
      for (let i = 6; i <= 20; i++) {
        const questionKey = `q${i}`;
        const answerKey = answers[questionKey];
        const questionText = questions[questionKey].question;
        const answerText = getAnswerText(questionKey, answerKey);

        query += `Q${i}: ${questionText}\n`;
        query += `A${i}: ${answerText}\n\n`;
      }

      // Remove extra newline at the end
      return query.trim();
    },

    submit() {
      const isValid = this.$refs.stepperForm.validate();
      if (!isValid) {
        return;
      }
      this.currentStep++;
      setTimeout(() => {
        this.isOpenQuestionnaireDialog = false;
        console.log("My Questionnaire Answers:\n", this.questionnaireFormData); // working
      }, 500);

      this.isLoading = true;

      const headers = {
        accept: "application/json",
        "Content-Type": "application/json",
        "project-key": this.credentialsFromData["projectKey"],
        "api-key": this.credentialsFromData["apiKey"],
      };
      const surveyResults = this.separateAndReorderProfilesData(
        this.questionnaireFormData
      );
      console.log(JSON.stringify(surveyResults, null, 2));
      console.log(
        "Kikusui Profile:",
        JSON.stringify(surveyResults.kikusuiProfile, null, 2)
      );
      console.log(
        "Taste Profile:",
        JSON.stringify(surveyResults.tasteProfile, null, 2)
      );
      const formattedQuery = this.generateQuery(
        this.tasteProfileQuestions,
        surveyResults.tasteProfile
      );
      console.log("Query:", formattedQuery);
      console.log("stringify Query:", JSON.stringify(formattedQuery));
      const {
        item_id,
        title,
        image_url,
        price,
        description,
        taste_type,
        ingredient_type,
        recommendedWayToConsume,
      } = this.requiredKeys;
      const resultIncludedColumns =
        taste_type === "N/A"
          ? [item_id, title, image_url, price, description]
          : [
              item_id,
              title,
              image_url,
              price,
              description,
              taste_type,
              ingredient_type,
              recommendedWayToConsume,
            ];
      const requestBody = {
        user_id: "a0cc6beb-2909-459b-be55-62196af78ce4",
        member_id: "df3456tg-2909-459b-be55-62196afedf85",
        questionnaire_id: this.credentialsFromData["questionnaireId"],
        values: surveyResults.kikusuiProfile,
        recommend_count: this.credentialsFromData["recommendationCount"],
        result_includes: resultIncludedColumns, // result_includes: ["JANコード", "商品名", "images", "参考価格", 商品説明(60文字), "甘辛区分", "特定名称", "飲み頃温度"],
        top_k: 20,
        is_keyword_enabled: true,
      };
      axios
        .post(
          "https://api.recommender.gigalogy.com/v1/questionnaire/recommend",
          requestBody,
          { headers }
        )
        .then((response) => {
          if (response.data.request_id) {
            console.log("Recommendations response:\n", response.data);
            const recommendations = response.data?.detail?.recommendations;
            const processedRecommendations = {};

            Object.entries(recommendations).forEach(([category, products]) => {
              processedRecommendations[category] = products.map((product) => ({
                item_id: product[this.requiredKeys.item_id],
                title: product[this.requiredKeys.title],
                image_url: product[this.requiredKeys.image_url],
                price: product[this.requiredKeys.price],
                description: product[this.requiredKeys.description],
                taste_type: product[this.requiredKeys.taste_type],
                ingredient_type: product[this.requiredKeys.ingredient_type],
                recommendedWayToConsume:
                  product[this.requiredKeys.recommendedWayToConsume],
              }));
            });
            console.log("processedRecommendations", processedRecommendations);

            this.userTestProfileTypes = this.processTasteProfile(
              processedRecommendations
            );

            this.recommendedProducts = processedRecommendations;

            // Second API call for GPT
            const gptRequestBody = {
              user_id: "a0cc6beb-2909-459b-be55-62196af78ce4",
              member_id: "df3456tg-2909-459b-be55-62196afedf85",
              query: JSON.stringify(formattedQuery),
              conversation_type: "question",
              gpt_profile_id: "b753ea26-e310-4079-8d1b-e8af3c7cee23",
              context_preference: {
                preferred: {
                  dataset_id: "7bfe2590-a096-4235-afa8-f83fccd18186",
                },
              },
            };

            return axios.post(
              "https://api.recommender.gigalogy.com/v1/gpt/ask",
              gptRequestBody,
              { headers }
            );
          }
        })
        .then((gptResponse) => {
          if (gptResponse) {
            console.log("GPT response:", gptResponse.data);
            // Handle GPT response here
            // You might want to store it in a data property
            // this.gptResponse = gptResponse.data;
            this.extractTasteClassMeaningAndExplanation(gptResponse.data);
          }

          this.isGotRecommendedProducts = true;
        })
        .catch((error) => {
          console.error("There was an error submitting the data!", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    showSnackbarNotifier(message, color, duration) {
      this.SNACKBAR_NOTIFIER({
        data: {
          isShow: true,
          message: message,
          color: color,
          duration: duration,
        },
      });
    },

    async getStartedWithQuestionnaire() {
      console.log(this.credentialsFromData, "Home page credentialsFromData");
      console.log(this.isValidCredentialsForm, "isValidCredentialsForm");

      // if (!this.isValidCredentialsForm) {
      //   this.showSnackbarNotifier(
      //     "Please fill in all required credentials before performing this action!",
      //     "warning",
      //     2000
      //   );
      //   return;
      // }
      const isAllFieldsEmpty =
        !this.credentialsFromData?.questionnaireId ||
        !this.credentialsFromData?.projectKey ||
        !this.credentialsFromData?.apiKey ||
        !this.credentialsFromData?.recommendationCount;

      if (isAllFieldsEmpty) {
        this.showSnackbarNotifier(
          "Please fill in all required credentials before performing this action!",
          "warning",
          2000
        );
        return;
      }
      this.currentStep = 0;
      this.isLoading = true;
      await this.getResultIncludesKeysByMapper();
      await this.fetchQuestionnaire();
    },

    fetchQuestionnaire() {
      // let Id = this.credentialsFromData["questionnaireId"];
      // const headers = {
      //   accept: "application/json",
      //   "project-key": this.credentialsFromData["projectKey"],
      //   "api-key": this.credentialsFromData["apiKey"],
      // };
      // axios
      //   .get(`https://api.recommender.gigalogy.com/v1/questionnaires/${Id}`, {
      //     headers,
      //   })
      axios
        .get("./data/mergedQuestionnaires.json")
        .then((response) => {
          console.log("Questionnaires Response:\n", response.data);
          this.mergedQuestions = response.data;
          const { name, questions } = response.data;
          this.steps = [];
          this.questionnaireName = name;

          Object.entries(questions).forEach(([section_name, questions_set]) => {
            if (!this.questionnaireFormData[section_name]) {
              console.log("inside", {
                ...this.questionnaireFormData[section_name],
              });
              this.$set(this.questionnaireFormData, section_name, {});
              // Process questions in each category_section
              Object.entries(questions_set).forEach(([key, value]) => {
                this.steps.push({ section_name, key, ...value });
                if (value.type === "checkbox") {
                  this.$set(this.questionnaireFormData[section_name], key, []);
                } else {
                  this.$set(this.questionnaireFormData[section_name], key, "");
                }
              });
            } else {
              // No need to reset the entire section, just ensure existing data is maintained
              Object.entries(questions_set).forEach(([key, value]) => {
                this.steps.push({ section_name, key, ...value });
                if (!(key in this.questionnaireFormData[section_name])) {
                  if (value.type === "checkbox") {
                    this.$set(
                      this.questionnaireFormData[section_name],
                      key,
                      []
                    );
                  } else {
                    this.$set(
                      this.questionnaireFormData[section_name],
                      key,
                      ""
                    );
                  }
                }
              });
              console.log("outside", {
                ...this.questionnaireFormData[section_name],
              });
            }
          });
          /* Object.entries(questions).forEach(([section_name, questions_set]) => {
            // Create a section object to hold questions for the current section
            const section = { section_name, questions: [] };

            if (!this.questionnaireFormData[section_name]) {
              console.log("inside", {
                ...this.questionnaireFormData[section_name],
              });
              this.$set(this.questionnaireFormData, section_name, {});

              // Process questions in each section
              Object.entries(questions_set).forEach(([key, value]) => {
                section.questions.push({ key, ...value });

                if (value.type === "checkbox") {
                  this.$set(this.questionnaireFormData[section_name], key, []);
                } else {
                  this.$set(this.questionnaireFormData[section_name], key, "");
                }
              });
            } else {
              // Ensure existing data is maintained without resetting the section
              Object.entries(questions_set).forEach(([key, value]) => {
                section.questions.push({ key, ...value });

                if (!(key in this.questionnaireFormData[section_name])) {
                  if (value.type === "checkbox") {
                    this.$set(
                      this.questionnaireFormData[section_name],
                      key,
                      []
                    );
                  } else {
                    this.$set(
                      this.questionnaireFormData[section_name],
                      key,
                      ""
                    );
                  }
                }
              });
              console.log("outside", {
                ...this.questionnaireFormData[section_name],
              });
            }

            // Push the entire section, with its questions, into steps
            this.steps.push(section);
          }); */
          console.log("Steps setup complete: ", this.steps);
          console.log(
            "questionnaireFormData setup complete: ",
            this.questionnaireFormData
          );
        })
        .catch((error) => {
          console.error("There was an error fetching the data!", error);
        })
        .finally(() => {
          setTimeout(() => {
            this.isLoading = false; // Turn off loader
            this.isOpenQuestionnaireDialog = true; // Open Questionnaire Dialog after 1 seconds
          }, 1000);
        });
    },

    getResultIncludesKeysByMapper() {
      const headers = {
        accept: "application/json",
        "project-key": this.credentialsFromData["projectKey"],
        "api-key": this.credentialsFromData["apiKey"],
      };
      axios
        .get(`https://api.recommender.gigalogy.com/v1/mappers`, { headers })
        .then((response) => {
          console.log("Get Mapper Keys Response: \n", response.data);
          const responseDetails = response.data?.detail?.response;
          const { item_id, title, image_url, price, description, categories } =
            responseDetails;
          console.log("categories", categories);
          this.requiredKeys.item_id = item_id;
          this.requiredKeys.title = title;
          this.requiredKeys.image_url = image_url;
          this.requiredKeys.price = price;
          this.requiredKeys.description = description;
          this.requiredKeys.taste_type =
            categories.find((column) => column.name === "甘辛区分")?.name ??
            "N/A";
          this.requiredKeys.ingredient_type =
            categories.find((column) => column.name === "特定名称")?.name ??
            "N/A";
          this.requiredKeys.recommendedWayToConsume =
            categories.find((column) => column.name === "飲み頃温度")?.name ??
            "N/A";
        })
        .catch((error) => {
          console.error("There was an error fetching the data!", error);
        })
        .finally(() => {});
    },

    animateProgress(newValue) {
      const animationDuration = 500; // milliseconds
      const fps = 60;
      const frameDuration = 1000 / fps;
      const totalFrames = animationDuration / frameDuration;

      let currentFrame = 0;
      const startValue = this.displayProgress;
      const changeInValue = newValue - startValue;

      const animate = () => {
        currentFrame++;
        const progress = currentFrame / totalFrames;
        this.displayProgress =
          startValue + changeInValue * this.easeOutCubic(progress);

        if (currentFrame < totalFrames) {
          requestAnimationFrame(animate);
        }
      };

      requestAnimationFrame(animate);
    },
    easeOutCubic(t) {
      return 1 - Math.pow(1 - t, 3);
    },
  },
  watch: {
    progressCalculate: {
      handler(newValue) {
        this.animateProgress(newValue);
      },
      immediate: true,
      deep: true,
    },
    currentStep(newStep) {
      if (newStep === 20) {
        this.isShowTasteProfileSectionMessage = true;
        // Hide the component after 3 seconds (3000 ms)
        // setTimeout(() => {
        //   this.isShowTasteProfileSectionMessage = false;
        // }, 3000);
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
.v-progress-linear {
  transition: width ease-in-out;
}
/* Shutter Out Horizontal */
.hvr-shutter-out-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: transparent;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-shutter-out-horizontal:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #2098d1;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-shutter-out-horizontal.active {
  color: white;
}
.hvr-shutter-out-horizontal.active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

/* Sweep To Right */
.hvr-sweep-to-right {
  padding: 20px;
  background: lightgrey;
  display: inline-block;
  transform: perspective(1px) translateZ(0);
  position: relative;
  transition: color 0.3s;
}
.hvr-sweep-to-right:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: #2098d1;
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition: transform 0.3s ease-out;
}
.hvr-sweep-to-right.active {
  color: white;
}
.hvr-sweep-to-right.active:before {
  transform: scaleX(1);
}

/* Center the taste-profile-start-content container */
.taste-profile-start-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 18px;
}

/* Button styling with hover gradient effect */
.continue-btn {
  padding: 5px 20px;
  background: transparent;
  color: white;
  border: 1px solid whitesmoke;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}
/* Gradient hover effect */
.continue-btn:hover {
  background: linear-gradient(to right, #07f7f7, transparent);
}
/* Custom styles with Vuetify class enhancements */
.questionnaire-container {
  /* Title font sizes */
  --title-font-size-desktop: 1.5rem; /* Baseline for desktop */
  --title-font-size-tablet: 1.2rem; /* 1.5rem ÷ 1.25 */
  --title-font-size-mobile: 0.96rem; /* 1.2rem ÷ 1.25 */

  /* Question font sizes */
  --question-font-size-desktop: 1.2rem; /* Baseline for desktop */
  --question-font-size-tablet: 0.96rem; /* 1.2rem ÷ 1.25 */
  --question-font-size-mobile: 0.77rem; /* 0.96rem ÷ 1.25 */

  /* Option font sizes */
  --option-font-size-desktop: 1rem; /* Baseline for desktop */
  --option-font-size-tablet: 0.9rem; /* 1rem ÷ 1.25 */
  --option-font-size-mobile: 0.74rem; /* 0.8rem ÷ 1.25 */
}

/* Enhanced Title Styles */
.questionnaire-title {
  font-size: var(--title-font-size-desktop) !important;
  transition: all 0.3s ease;
  padding: 16px 20px !important;
}

/* Question Text Enhancement */
.question-text {
  font-size: var(--question-font-size-desktop);
  margin-bottom: 0.5rem;
  transition: all 0.3s ease;
}

.option-text {
  font-size: var(--option-font-size-desktop) !important;
  transition: font-size 0.3s ease;
}

@media (max-width: 600px) {
  .questionnaire-title {
    font-size: var(--title-font-size-mobile) !important;
    padding: 12px !important;
  }

  .question-text {
    font-size: var(--question-font-size-mobile) !important;
    margin-bottom: 0.25rem;
  }

  .option-text /deep/ .v-label{
    font-size: var(--option-font-size-mobile) !important;
  }
  
}
</style>
