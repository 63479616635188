import Vue from "vue";
import Vuex from "vuex";
import { createStore } from 'vuex-extensions';
import { credentials_form } from "./modules/credentials_form";
import { snackbar_notifier } from "./modules/snackbar_notifier";

Vue.use(Vuex);
const store = createStore(Vuex.Store, {
    modules: {
        credentials_form,
        snackbar_notifier,
    },
});

export default store;