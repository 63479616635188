import { render, staticRenderFns } from "./CredentialsForm.vue?vue&type=template&id=0a3def10&scoped=true"
import script from "./CredentialsForm.vue?vue&type=script&lang=js"
export * from "./CredentialsForm.vue?vue&type=script&lang=js"
import style0 from "./CredentialsForm.vue?vue&type=style&index=0&id=0a3def10&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a3def10",
  null
  
)

export default component.exports