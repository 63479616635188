import { SNACKBAR_NOTIFIER } from "../action_types";
import { UPDATE_SNACKBAR_NOTIFIER } from "../mutation_types";

const state = {
    snackbar: {
        isShow: false,
        message: '',
        color: '',
        duration: -1,
    },
};

const actions = {
    [SNACKBAR_NOTIFIER]({ commit }, { data }) {
        console.log("snackbar action data:\n", data)
        commit(UPDATE_SNACKBAR_NOTIFIER, data);
    },
};

const mutations = {
    [UPDATE_SNACKBAR_NOTIFIER](state, data) {
        console.log("snackbar mutation data:\n", data)
        state.snackbar = { ...state.snackbar, ...data };
    },
};

const getters = {
    snackbar: (state) => state.snackbar
};

export const snackbar_notifier = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
