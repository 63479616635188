<template>
  <div class="text-center">
    <v-snackbar
      v-model="snackbar.isShow"
      :timeout="snackbar.duration"
      :color="snackbar.color"
      text
      top
    >
      {{ snackbar.message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbarClose" icon>
          <v-icon>mdi-close-thick</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { SNACKBAR_NOTIFIER } from "@/store/action_types";
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({}),
  props: {},
  computed: {
    ...mapGetters("snackbar_notifier", ["snackbar"]),
  },
  methods: {
    ...mapActions("snackbar_notifier", [SNACKBAR_NOTIFIER]),
    snackbarClose() {
      this.SNACKBAR_NOTIFIER({ data: { isShow: false } });
    },
  },
};
</script>
<style></style>
