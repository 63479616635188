<!-- <template>
  <v-card
    elevation="0"
    outlined
    class="d-flex flex-column px-4"
    height="30rem"
  >
    <div class="pt-4">
      <v-img :src="image" :alt="name" height="200" contain></v-img>
    </div>

    <v-card-title class="pb-0">
      <div class="text-h6">{{ name }}</div>
    </v-card-title>

    <v-card-text class="flex-column flex-grow-1 pb-0">
      <v-row align="center" class="mx-0 mb-2 no-gutters">
        <v-rating
          :value="average_rating"
          color="amber"
          dense
          half-increments
          readonly
          size="14"
        ></v-rating>
        <div class="grey--text ms-2">
          {{ `${average_rating} (${user_ratings_total})` }}
        </div>
      </v-row>

      <div class="text-h6 mb-2">
        {{ `¥${price.toFixed(2)}` }}
      </div>

      <div class="text-body-2 text-justify">
        {{ truncateText(description, 42) }}
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        style="background-color: #1e88e5; opacity: 0.9"
        color="white"
        text
        @click="ProductDetails"
      >
        Place Order
      </v-btn>
    </v-card-actions>
  </v-card>
</template> -->

<template>
  <div>
    <v-card elevation="0" outlined class="d-flex flex-column flex-sm-row">
      <div
        class="d-flex justify-center"
        :style="{
          width: $vuetify.breakpoint.xs ? 'auto' : '12.625rem',
          height: $vuetify.breakpoint.xs ? '10.5rem' : '12.5rem',
          padding: '1rem 0px',
        }"
      >
        <v-img class="p-4" :src="image" :alt="name" contain></v-img>
      </div>

      <div class="d-flex flex-column flex-grow-1 pa-4">
        <v-card-title class="pb-1 pt-0">
          <div
            class="font-weight-bold"
            :class="[$vuetify.breakpoint.xs ? 'text-subtitle-2' : 'text-h6']"
          >
            {{ name }}
          </div>
        </v-card-title>

        <v-card-text class="py-1 flex-grow-1 d-flex flex-column">
          <div class="mb-1" :class="[$vuetify.breakpoint.xs ? 'text-body-2' : 'text-subtitle-1']">
            {{ `¥${formatPrice(price)}` }}
          </div>
          
          <div :class="[$vuetify.breakpoint.xs ? 'text-caption' : 'text-body-2']">
            <div class="mb-1">
              <span class="font-weight-bold"></span>
              {{
                `${tasteType} ${
                  ingredientType.length > 0 ? `(${ingredientType})` : ""
                }`.trim()
              }}
            </div>

            <div class="mb-1">
              <span>オススメの飲み方:</span>
              {{ `${recommendedWayToDrink}` }}
            </div>
          </div>
        </v-card-text>

        <v-card-actions class="py-0">
          <v-spacer></v-spacer>
          <v-btn
            style="background-color: #1e88e5; opacity: 0.9"
            color="white"
            text
            @click="openProductDetailsDialog"
            :small="$vuetify?.breakpoint?.smAndDown"
          >
            details
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
    <ProductDetails
      :isOpenDialog.sync="showDialog"
      :selectedProduct="productDetails"
    />
  </div>
</template>
<script>
import ProductDetails from "./ProductDetails.vue";

export default {
  name: "ProductCard",
  components: {
    ProductDetails,
  },
  computed: {
    productDetails() {
      return {
        id: this.id,
        name: this.name,
        image: this.image,
        price: this.formatPrice(this.price),
        description: this.description,
        categories: this.categories,
        tasteType: this.tasteType,
        ingredientType: this.ingredientType,
        recommendedWayToDrink: this.recommendedWayToDrink,
      };
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    price: {
      type: Number,
      default: 0,
    },
    description: {
      type: String,
      default: "",
    },
    category: {
      type: String,
      default: "",
    },
    averageRating: {
      type: Number,
      default: 0,
    },
    userRatingsTotal: {
      type: Number,
      default: 0,
    },
    tasteType: {
      type: Array,
      default: () => [],
    },
    ingredientType: {
      type: Array,
      default: () => [],
    },
    recommendedWayToDrink: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    openProductDetailsDialog() {
      this.showDialog = true;
    },
    formatPrice(price) {
      if (price < 1000) {
        return price.toString();
      }
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    truncateText(text, length) {
      if (text.length <= length) {
        return text;
      }
      return text.slice(0, length) + "...";
    },
  },
};
</script>
<style scoped>
.text-truncate-three-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.body-text-container {
  /* Body font sizes */
  --body-font-size-desktop: 1rem; /* 16px */
  --body-font-size-tablet: 0.875rem; /* 14px */
  --body-font-size-mobile: 0.8125rem; /* 13px */
}
.body-text {
  font-size: var(--body-font-size-desktop) !important;
  transition: all 0.3s ease;
}
@media (max-width: 600px) {
  .body-text{
    font-size: var(--body-font-size-mobile) !important;
  }
}
</style>
